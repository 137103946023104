import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { API_CONFIG } from "src/config/api.config";
import { STORAGE_KEYS } from "src/config/storage_keys.config";
import { CredenciaisDTO } from "../classes/dto/credenciais.dto";
import { LocalUser } from "../classes/dto/local_user";


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    apriUrl = `${API_CONFIG.baseUrl}/auth/forgot`;

    jwtHelper: JwtHelperService  = new JwtHelperService ();

    constructor(
        public httpClient: HttpClient,
        ) {

    }

    authenticate(creds: CredenciaisDTO) {
        return this.httpClient.post(
            `${API_CONFIG.baseUrl}/login`,
            creds,
            {
                observe: 'response',
                responseType: 'text'
            })
    }

    successfullLogin(authorizationValue: string) {
        let tok = authorizationValue.substring(7);
        let user: LocalUser = {
            token: tok,
            email: this.jwtHelper.decodeToken(tok).sub,
            id: this.jwtHelper.decodeToken(tok).id,
            desconto: this.jwtHelper.decodeToken(tok).desconto,
        };
        this.setLocalUser(user);
    }

    logout(){
        this.setLocalUser(null);
    }

    getLocalUser(){
        let usr = localStorage.getItem(STORAGE_KEYS.localUser)
        if(usr == null){
            return null;
        } else {
            return JSON.parse(usr);
        }
    }

    setLocalUser(obj: LocalUser){
        if(obj == null){
            localStorage.removeItem(STORAGE_KEYS.localUser);
        } else{
            localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
        }
    }

    novaSenha(email) {
        return this.httpClient.post<any>(this.apriUrl, email).subscribe();
    }
}