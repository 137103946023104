import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_CONFIG } from "src/config/api.config";
import { Venda } from "../classes/productvenda";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})

export class VendaService {
    apriUrl = `${API_CONFIG.baseUrl}/venda`;
    postId;
    putId;

    token = this.storage.getLocalUser().token;
    authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.storage.getLocalUser().token });

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private httpClient: HttpClient,
        private storage: AuthService
    ) { }

    public getVendas(): Observable<Venda[]> {
        return this.httpClient.get<Venda[]>(this.apriUrl, { 'headers': this.authHeader });
    }

    public getVendaById(ident: any): Observable<Venda[]> {
        return this.httpClient.get<Venda[]>(this.apriUrl + '/' + ident, { 'headers': this.authHeader });
    }

    public getVendaByIdU(ident: any): Observable<Venda> {
        return this.httpClient.get<Venda>(this.apriUrl + '/' + ident, { 'headers': this.authHeader });
    }

    public getVendasAgenciaEmissao(ident: any, idProduto: any, status: any, dataInical: any, dataFinal: any): Observable<Venda[]> {
        return this.httpClient.get<Venda[]>(this.apriUrl + '/agencia/' + ident
            + "?idProduto=" + idProduto + "&status=" + status
            + "&dataInicial=" + dataInical + "&dataFinal=" + dataFinal, { 'headers': this.authHeader });
    }

    public getVendasAgenciaTour(ident: any, idProduto: any, status: any, dataInical: any): Observable<Venda[]> {
        return this.httpClient.get<Venda[]>(this.apriUrl + '/agencia/datatour/' + ident
            + "?idProduto=" + idProduto + "&status=" + status
            + "&data=" + dataInical, { 'headers': this.authHeader });
    }

    public getVendasAgenciaPassageiro(ident: any, passageiro: any): Observable<Venda[]> {
        return this.httpClient.get<Venda[]>(this.apriUrl + '/agencia/passageiro/' + ident
            + "?passageiro=" + passageiro, { 'headers': this.authHeader });
    }

    public getVendasAgenciaVenda(ident: any, idvendapesquisa: any): Observable<Venda[]> {
        return this.httpClient.get<Venda[]>(this.apriUrl + '/agencia/idvenda/' + ident
            + "?idVenda=" + idvendapesquisa, { 'headers': this.authHeader });
    }

    public postVenda(venda: any, tipo: any, parcelas: number): Observable<Venda> {
        // return null;
        return this.httpClient.post(this.apriUrl + '?qtdParcelas='
            + parcelas + '&tipoRec=' + tipo, venda, {
            'headers': this.authHeader
        });
    }

    public postVendaSite(venda: any, tipo: any, parcelas: number, token: any): Observable<Venda> {
        // return null;
        console.log(token)
        let authHeaderSite = new HttpHeaders({ 'Authorization': token });
        console.log(authHeaderSite)
        return this.httpClient.post(this.apriUrl + '?qtdParcelas='
            + parcelas + '&tipoRec=' + tipo, venda, {
            'headers': authHeaderSite
        });
    }

    public postVendaLink(venda: any, tipo: any, parcelas: number): Observable<Venda> {
        // return null;
        return this.httpClient.post(this.apriUrl + '/linkpagamento?qtdParcelas='
            + parcelas + '&tipoRec=' + tipo, venda, {
            'headers': this.authHeader
        });
    }

    public getComprovante(idVenda: any): Observable<Blob> {
        return this.httpClient.get(this.apriUrl + '/comprovante/' + idVenda, {
            responseType: 'blob',
            'headers': this.authHeader
        });
    }

    public getEmailComprovante(idVenda: any, email: any): Observable<any> {
        return this.httpClient.get<any>(this.apriUrl + '/comprovanteenviar/' + idVenda + '?email=' + email, {
            'headers': this.authHeader
        });
    }

    public putVenda(venda: any, ident: any): Observable<any[]> {
        return this.httpClient.put<any>(this.apriUrl + '/' + ident, venda, { 'headers': this.authHeader });
    }

    public async deleteVenda(ident: any) {
        return this.httpClient.delete<any>(this.apriUrl + '/' + ident, { 'headers': this.authHeader }).subscribe(
            data => { this.putId = data.id; }
        );
    }
}