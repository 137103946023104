<div class="img-wrapper">
	<div class="lable-block">
	</div>
	<div class="front">
        <a [routerLink]="['/shop/product/left/sidebar/', product.nome.replace(' ', '-')]">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
                 [lazyLoad]="ImageSrc ? ImageSrc : product.imagens[0].local" 
                 class="lazy-loading" alt="" height="200" width="auto">
        </a>
	</div>
	<div class="cart-box">
		<button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal" hidden>
            <i class="ti-shopping-cart"></i>
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal" hidden>
            <i class="ti-shopping-cart"></i>
        </button>
		<a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)" hidden>
            <i class="ti-heart" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)" hidden>
            <i class="ti-reload" aria-hidden="true"></i>
        </a>
	</div>
</div>
<div class="product-detail text-center mt-2 mb-2">
	<div class="rating" hidden>
		<bar-rating [rate]="5" [readOnly]="true" class="text-center pro-rating"></bar-rating>
	</div>
    <a [routerLink]="['/shop/product/left/sidebar/', product.nome.replace(' ', '-')]">
        <h3>{{ product.nome | titlecase }}</h3>
    </a>
    <h4>
        {{ product?.valor * currency?.valor | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.valor * currency?.valor | currency:currency?.currency:'symbol' }}</span></del>
    </h4>
    <ul class="color-variant" *ngIf="Color(product?.detalhesProdutos).length">
      <li [class]="color" *ngFor="let color of Color(product?.detalhesProdutos)" [ngStyle]="{'background-color': color}"
        (click)="ChangeVariants(color, product)">
      </li>
    </ul>
</div>
<div class="product-buttons">
    <a class="btn btn-solid" [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]">
      <h4 class="text-white" *ngIf="product?.valor > 0">a partir de:</h4>
      <h3 class="text-white" *ngIf="product?.valor > 0">{{ product?.valor | currency:'BRL':'symbol' }}</h3>
      <h4 class="text-white" *ngIf="product?.valor <= 0">Consultar</h4>
      <h3 class="text-white" *ngIf="product?.valor <= 0">Condições</h3>
      <p style="color: #ffda07;">Ir para tela do produto</p>
    </a>
  </div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>