import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../../../shared/services/product.service';
import { Passageiro, ProductVenda, Venda } from 'src/app/shared/classes/productvenda';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { empty } from 'rxjs';
import { PassageiroInic } from 'src/app/shared/classes/passageiroiniciado';
import { VendaService } from 'src/app/shared/services/venda.service';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-cancelarvenda',
  templateUrl: './cancelarvenda.component.html',
  styleUrls: ['./cancelarvenda.component.scss'],
  styles: [
    `
			.inputpequeno {
				padding-top: 5px;
			}
		`,
  ],
  animations: [
    trigger('spin', [
      transition('* => *', [
        animate('2s linear', keyframes([
          style({ transform: 'translate(44%)    rotateY(0deg)', offset: 0 }),
          style({ transform: 'translate(44%)   rotateY(13deg)', offset: 0.25 }),
          style({ transform: 'translate(44%)  rotateY(20deg)', offset: 0.50 }),
          style({ transform: 'translate(44%)  rotateY(13deg)', offset: 0.75 }),
          style({ transform: 'translate(44%) ', offset: 1.0 })
        ]))
      ])
    ])
  ],
})
export class CancelarVendaComponent implements OnInit, OnDestroy {

  @Input() vendaSelected: Venda;
  @ViewChild("cancelarVenda", { static: false }) CencelarVenda: TemplateRef<any>;
  @ViewChild('esperaModal', { static: false }) esperaModal;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public tipo;

  spinState = 'start';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    private productService: ProductService,
    private vendaService: VendaService,
    private toastrService: ToastrService,
    private viewScroller: ViewportScroller,
  ) { }

  ngOnInit(): void {
    console.log(this.vendaSelected)
  }

  ngAfterViewInit(): void {
    this.loopAnimation();
  }

  loopAnimation() {
    setInterval(() => {
      this.spinState = this.spinState === 'start' ? 'stop' : 'start';
    }, 2000); // Repetir a cada 2 segundos para simular "infinite"
  }

  espera() {
    this.modalService.open(this.esperaModal, {
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static'
    });
  }

  fecharEspera() {
    this.modalService.dismissAll();
  }

  openModal(tipo) {
    this.tipo = tipo;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.CencelarVenda, {
        size: 'md',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Passageiro'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    console.log(this.vendaSelected)
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  async onSubmit() {
    this.viewScroller.scrollToPosition([0, 0]);
    let passageirosAtuais: Passageiro[] = [];
    this.modalService.dismissAll();
  }

  closeBtnClick() {
    this.modalService.dismissAll();
    this.ngOnInit();
  }


  atualizarVenda(venda, statusA) {
    this.espera();
    venda.status = statusA;
    venda.usuario.perfis = venda.usuario.perfisGeral;
    console.log(venda)
    this.vendaService.putVenda(venda, venda.id).subscribe(
      data => {
        this.fecharEspera();
        alert('Cancelado com sucesso')
        console.log(data)
      },
      error => {
        alert("ERRO! Não foi possível cancelar.")
      }
    )
  }
}
