import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-linksite',
  templateUrl: './linksite.component.html',
  styleUrls: ['./linksite.component.scss']
})
export class LinkSiteComponent implements OnInit {

  public url: any;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit(): void {
  }

}
