import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../../../shared/services/product.service';
import { Passageiro, ProductVenda } from 'src/app/shared/classes/productvenda';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { empty } from 'rxjs';
import { PassageiroInic } from 'src/app/shared/classes/passageiroiniciado';

@Component({
  selector: 'app-vendacadastrada',
  templateUrl: './vendacadastrada.component.html',
  styleUrls: ['./vendacadastrada.component.scss'],
  styles: [
    `
			.inputpequeno {
				padding-top: 5px;
			}
		`,
  ],
})
export class VendaCadastradaComponent implements OnInit, OnDestroy {

  @Input() productVenda: ProductVenda;
  @ViewChild("vendacadastrada", { static: false }) Passageiro: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public inserirPassageiros: FormGroup;
  public tipo;
  public datasSelecionadas: Date = JSON.parse(localStorage.getItem('dataSelecionada'));

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    public productService: ProductService,
    private toastrService: ToastrService,
    private viewScroller: ViewportScroller,
  ) { }

  ngOnInit(): void {
    console.log(this.productVenda)
  }

  openModal(tipo) {
    this.tipo = tipo;
    this.modalOpen = true;
    console.log("Teste")
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.Passageiro, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Passageiro'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    this.productVenda = JSON.parse(localStorage.getItem('produtoVenda'));
    console.log(this.productVenda)
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  async onSubmit() {
    this.viewScroller.scrollToPosition([0, 0]);
    let passageirosAtuais: Passageiro[] = [];
    this.productVenda.passageiros.forEach(pass => {
      if(pass?.nome){
        passageirosAtuais.push(pass);
      }
    })
    this.productVenda.passageiros = passageirosAtuais;
    let testenome = this.productVenda.passageiros.find(passag => passag.nome == '');
    let testenascimento = this.productVenda.passageiros.find(passag => passag.nascimento == null);
    if (testenome) {
      this.toastrService.error('Necessário preencher todos os campos de nome', 'Erro Nome', { timeOut: 7000, progressBar: true, progressAnimation: 'increasing' });
    }
    if (testenascimento) {
      this.toastrService.error('Necessário preencher todos os campos de data de nascimento', 'Erro Nascimento', { timeOut: 7000, progressBar: true, progressAnimation: 'increasing' });
    }

    if (testenome || testenascimento) {
      this.toastrService.error('Não foi possível gravar os dados', 'Erro', { timeOut: 7000, progressBar: true, progressAnimation: 'increasing' });
    } else {
      // this.productVenda.tarifas = this.productVenda.tarifas.filter(tarifa => tarifa.status == 'Ativo'
      //   && new Date(tarifa.data.data).getFullYear() == new Date(this.datasSelecionadas).getFullYear()
      //   && new Date(tarifa.data.data).getMonth() == new Date(this.datasSelecionadas).getMonth()
      //   && new Date(tarifa.data.data).getDate() == new Date(this.datasSelecionadas).getDate()
      // )]
      this.productVenda.opcionais = this.productVenda.opcionais.filter(op => op.checked == true)

      console.log(this.productVenda)

      const status = await this.productService.addToCart(this.productVenda);
      if (status) {
        if (this.tipo == 'comprar') {
          this.router.navigate(['/shop/checkout']);
        } else {
          this.router.navigate(['/shop/cart']);
        }
      }
      this.modalService.dismissAll();
    }
  }

  inserirDadosPassageiro(idPassageiro: any, event: any, variavel: string){
    let val = event.target.value;

    if(variavel == "nome") this.productVenda.passageiros[idPassageiro].nome = val;
    if(variavel == "nascimento") this.productVenda.passageiros[idPassageiro].nascimento = val;
    if(variavel == "cpf") this.productVenda.passageiros[idPassageiro].cpf = val;
    if(variavel == "telefone") this.productVenda.passageiros[idPassageiro].telefone = val;
    if(variavel == "celular") this.productVenda.passageiros[idPassageiro].celular = val;
    if(variavel == "email") this.productVenda.passageiros[idPassageiro].email = val;
    if(variavel == "obs") this.productVenda.passageiros[idPassageiro].obs = val;
  }

  public removeItem(idPassageiro: any) {
    delete this.productVenda.passageiros[idPassageiro];
  }

  adicionarPassageiro(){
    this.productVenda.passageiros.push(new PassageiroInic);
  }
}
