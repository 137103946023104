<ng-template class="theme-modal" #passageiro let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <form name="passageirosform">
                <div class="row">
                    <div class="col-lg-12 rtl-text">
                        <div class="product-right">
                            <h2>{{productVenda.nome}} Teste </h2>
                            <div class="border-product" *ngFor="let passageiro of productVenda.passageiros; index as i">
                                <div *ngIf="productVenda?.passageiros[i]">
                                    <h6 class="product-title">Dados Passageiros - {{i + 1}}</h6>
                                    <a href="javascript:void(0)" (click)="removeItem(i)" class="icon">
                                        Excluir - <i class="ti-close"></i>
                                    </a>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label>Nome Passageiro</label>
                                                <input type="text" class="form-control form-control-sm"
                                                    placeholder="Inserir nome aqui"
                                                    [value]="productVenda?.passageiros[i]?.nome"
                                                    (ngModel)="productVenda?.passageiros[i]?.nome"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'nome')">
                                            </div>

                                            <div class="col-md-2">
                                                <label>Nascimento</label>
                                                <input type="date" class="form-control form-control-sm"
                                                    [value]="productVenda?.passageiros[i]?.nascimento"
                                                    (ngModel)="productVenda?.passageiros[i]?.nascimento"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'nascimento')">
                                            </div>
                                            <div class="col-md-2">
                                                <label>Dados CPF ou outro documento</label>
                                                <input type="text" class="form-control form-control-sm"
                                                    placeholder="Inserir CPF ou doc"
                                                    [value]="productVenda?.passageiros[i]?.cpf"
                                                    (ngModel)="productVenda?.passageiros[i]?.cpf"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'cpf')">
                                            </div>
                                            <div class="col-md-2">
                                                <label>Telefone</label>
                                                <input type="text" class="form-control form-control-sm"
                                                    placeholder="Inserir Telefone"
                                                    [value]="productVenda?.passageiros[i]?.telefone"
                                                    (ngModel)="productVenda?.passageiros[i]?.telefone"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'telefone')">
                                            </div>
                                            <div class="col-md-2">
                                                <label>Celular</label>
                                                <input type="text" class="form-control form-control-sm"
                                                    placeholder="Inserir Celular"
                                                    [value]="productVenda?.passageiros[i]?.celular"
                                                    (ngModel)="productVenda?.passageiros[i]?.celular"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'celular')">
                                            </div>
                                        </div>
                                        <div class="row inputpequeno">
                                            <div class="col-md-4">
                                                <label>Email</label>
                                                <input type="email" class="form-control form-control-sm"
                                                    placeholder="Inserir Email" [value]="productVenda?.passageiros[i]?.email"
                                                    (ngModel)="productVenda?.passageiros[i]?.email"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'email')">
                                            </div>
                                            <div class="col-md-8">
                                                <label>Informações</label>
                                                <input type="text" class="form-control form-control-sm"
                                                    placeholder="Inserir observação"
                                                    [value]="productVenda?.passageiros[i]?.obs"
                                                    (ngModel)="productVenda?.passageiros[i]?.obs"
                                                    (focusout)="inserirDadosPassageiro(i, $event, 'obs')">
                                            </div>
                                        </div>
                                        <!-- <div class="row inputpequeno">
                                        <div class="col-md-4">
                                            <input type="email" class="form-control form-control-sm"
                                                 id="email" name="email" placeholder="Email"
                                                [(ngModel)]="productVenda.passageiros[i].email">
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control form-control-sm"
                                                id="obs" name="obs" placeholder="Inserir observação"
                                                [(ngModel)]="productVenda.passageiros[i].obs">
                                        </div>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-6 text-center">
                                    <button class="btn btn-solid p-3" (click)="onSubmit()">Salvar</button>
                                </div>
                                <div class="col-md-6 text-center">
                                    <button class="btn btn-solid p-3" (click)="adicionarPassageiro()">Adicionar
                                        Passageiro</button>
                                </div>
                            </div>
                            <div class="product-description border-product">
                                <h6 class="product-title">Quantidade de Pessoas</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>