<div class="img-wrapper">
    <div class="img-block">
        <div class="lable-wrapper">
        </div>
        <div class="front">
            <a [routerLink]="['/shop/product/left/sidebar/', product.nome.replace(' ', '-')]">
                <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
                     [lazyLoad]="ImageSrc ? ImageSrc : product.imagens[0].local"  
                     class="img-fluid lazy-loading" alt="{{ product.imagens[0].nome }}">
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a [routerLink]="['/shop/product/left/sidebar/', product.nome.replace(' ', '-')]">
                <img [src]="ImageSrc ? ImageSrc : product.imagens[1].local" class="img-fluid lazy-loading" alt="{{ product.imagens[1].nome }}">
            </a>
        </div>
        <div class="cart-info">
            <button title="Add to cart" (click)="CartModal.openModal(product)" 
                *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </button>
            <button title="Add to cart" (click)="addToCart(product)"
                *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </button>
            <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
              <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
              <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
              <i class="ti-reload" aria-hidden="true"></i>
            </a>
        </div>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product/left/sidebar/', product?.nome.replace(' ', '-')]">
        <h6>{{ product?.nome | titlecase }}</h6>
      </a>
      <h4>
        {{ product?.valor * currency?.valor | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.valor * currency?.valor | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
      <ul class="color-variant" *ngIf="Color(product?.detalhesProdutos).length">
        <li [class]="color" *ngFor="let color of Color(product?.detalhesProdutos)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul>
    </div>
  </div>
  
  <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
  <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
  
  