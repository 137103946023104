<div *ngIf="!loader">
  <a [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]">
  <div class="img-wrapper">
    <div class="front">
      <a [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]">
        <img [defaultImage]="ImagemLocal ? ImagemLocal : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImagemLocal ? ImagemLocal : product?.imagens[0]?.local" class="lazy-loading"
          alt="{{ product?.imagens[0]?.nome }}" height="170" width="auto" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]">
        <img [src]="ImagemLocal ? ImagemLocal : product?.imagens[0]?.local" class="lazy-loading"
          alt="{{ product?.imagens[0]?.nome }}" height="170" width="auto">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImagemLocal == image.local" *ngFor="let image of product?.imagens">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image?.local)">
          <img [lazyLoad]="image?.local" height="170" width="auto">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]" title="Comprar">
        <i class="ti-shopping-cart"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
        </a> -->
      <!-- <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
        </a> -->
      <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
        </a> -->
    </div>
  </div>
  <div class="lable-block mt-2 text-black">
    <h4>{{ product?.nome }}</h4>
    <!-- <span class="lable3" *ngIf="product.new">new</span>
    <span class="lable4" *ngIf="product.sale">on sale</span> -->
  </div>
  <div class="product-detail">
    <div class="descproduto">
      <a [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]">
        <h6 *ngIf="product?.horarioSaida">Saída: {{ product?.horarioSaida }}</h6>
        <h6 *ngIf="product?.tipoProduto?.nome == 'Transfer'">Capacidade: {{ product?.capacidade }}</h6>
        <h6>{{ product?.periodicidade }}</h6>
        <!-- <h6>{{ product?.tipoProduto?.nome | titlecase }}</h6> -->
      </a>
    </div>
  </div>
  <div class="product-buttons">
    <a class="btn btn-solid" [routerLink]="['/shop/product/left/sidebar/', product?.nome?.replace(' ', '-')]">
      <h4 class="text-white" *ngIf="product?.valor > 0">a partir de:</h4>
      <h3 class="text-white" *ngIf="product?.valor > 0">{{ product?.valor | currency:'BRL':'symbol' }}</h3>
      <h4 class="text-white" *ngIf="product?.valor <= 0">Consultar</h4>
      <h3 class="text-white" *ngIf="product?.valor <= 0">Condições</h3>
      <p style="color: #eee383;">Abrir produto</p>
    </a>
  </div>
  </a>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<!-- <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->