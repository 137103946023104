import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../../services/product.service';
import { Passageiro, ProductVenda, Venda } from 'src/app/shared/classes/productvenda';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { empty } from 'rxjs';
import { PassageiroInic } from 'src/app/shared/classes/passageiroiniciado';
import { VendaService } from 'src/app/shared/services/venda.service';
import { ProdutoVendaService } from 'src/app/shared/services/produtovenda.service';

@Component({
  selector: 'app-enviaremail',
  templateUrl: './enviaremail.component.html',
  styleUrls: ['./enviaremail.component.scss'],
  styles: [
    `
			.inputpequeno {
				padding-top: 5px;
			}
		`,
  ],
})
export class EnviarEmailComponent implements OnInit, OnDestroy {

  @Input() vendaSelected: Venda;
  @ViewChild("enviarEmail", { static: false }) EnviarEmail: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public tipo;
  public idProd;
  public emailAlternativo;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    private productService: ProductService,
    private vendaService: VendaService,
    private toastrService: ToastrService,
    private viewScroller: ViewportScroller,
    private produtoVendidoServico: ProdutoVendaService
  ) { }

  ngOnInit(): void {
    console.log(this.vendaSelected)
  }

  openModal(tipo, idProd) {
    console.log(tipo)
    this.tipo = null;
    this.idProd = null;
    this.emailAlternativo = null;
    this.tipo = tipo;
    this.idProd = idProd;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.EnviarEmail, {
        size: 'md',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Enviar Email'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  async onSubmit() {
    this.viewScroller.scrollToPosition([0, 0]);
    let passageirosAtuais: Passageiro[] = [];
    this.modalService.dismissAll();
  }

  closeBtnClick() {
    this.modalService.dismissAll();
    this.ngOnInit();
  }


  enviarEmailArquivos(item) {
    if(this.emailAlternativo == undefined) this.emailAlternativo = null;
    if(this.tipo == 'comprovante'){
      this.closeBtnClick();
      this.vendaService.getEmailComprovante(item.id, this.emailAlternativo).subscribe(
        response => {
          console.log(response);
        }
      )
    } else if ( this.tipo == 'voucher'){
      this.closeBtnClick();
      this.produtoVendidoServico.getEmailVoucher(this.idProd, this.emailAlternativo).subscribe(
        response => {
          console.log(response);
        }
      )
    }
  }
}
