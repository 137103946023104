<!--footer section -->
<footer [class]="class" *ngIf="usuarioId != null">
	<div class="light-layout background-color: top-header" *ngIf="newsletter" hidden>
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4 style="color: white;">SAIBA TUDO PRIMEIRO!</h4>
								<p>Nunca perca nada da Confiança ao se inscrever em nosso boletim informativo. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Insira seu email">
							</div>
							<button type="submit" class="btn btn-solid">Enviar</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p> -->
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://pt-br.facebook.com/tourregular/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li> -->
                                <li>
                                    <a href="https://www.instagram.com/confiancatur/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<!-- <div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Womens Fashion</a></li>
                                <li><a href="javascript:void(0)">Mens Fashion</a></li>
                                <li><a href="javascript:void(0)">Kids Fashion</a></li>
                                <li><a href="javascript:void(0)">Featured</a></li>
                            </ul>
                        </div>
					</div>
				</div> -->
				<!-- <div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">shipping & return</a></li>
                                <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Informações da Empresa</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Av. São Sebastião, 2852 - Quilombo, Cuiabá - MT, 78045-305</li>
						        <li><i class="fa fa-phone"></i>Telefone: (65) 3314-2700</li>
						        <li><i class="fa fa-envelope-o"></i>Email: <a style="text-transform: lowercase;">receptivo@confiancaturismo.com.br</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Confiança Turismo</p>
                  </div>
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->