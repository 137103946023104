import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { error } from 'console';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;
  public emailUser = JSON.parse(localStorage.getItem('localUserTour'));

  usuarioId= null;

  constructor(
    public usuarioService: UsuarioService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.getUsuario();
  }

  ngAfterViewInit(): void {

  }

  getUsuario() {
    this.usuarioId = JSON.parse(localStorage.getItem('localUserTour'))
    if (this.usuarioId != null) {
      var token = JSON.parse(localStorage.getItem('localUserTour')).token;
      this.usuarioService.getUsuarioByIdV(this.usuarioId.id, token).subscribe(data => {
        this.themeLogo = data.cliente.imagens[0].local;
        localStorage.setItem('localUserTourLogado', 'validado');
        localStorage.setItem('localUserTourCliente', data.cliente.nome);
      },
        error => {
          //this.router.navigate(['/pages/login']);
        },
      )
    } else {
      //this.router.navigate(['/pages/login']);
    }
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  logoutS() {
    localStorage.clear();
    this.router.navigate(['/pages/login']);
  }

}
