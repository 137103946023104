import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { API_CONFIG } from 'src/config/api.config';
import { AuthService } from './auth.service';
import { Produto } from '../classes/produto';
import { ProductVenda } from '../classes/productvenda';
import { response } from 'express';
import { TipoCliente } from '../dto/tipocliente';
import { TipoClienteService } from './tipocliente.service';
import { STORAGE_KEYS } from 'src/config/storage_keys.config';
import { CredenciaisDTO } from '../dto/credenciais.dto';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apriUrl = `${API_CONFIG.baseUrl}/produto`;
  postId;
  putId;

  token = this.storage?.getLocalUser()?.token;
  authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.storage?.getLocalUser()?.token });

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public Currency = { name: 'Real', currency: 'BRL', valor: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private storage: AuthService,
  ) { }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    //this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));

    let tokenAcesso;

    if (this.storage?.getLocalUser()?.token == undefined) {
      let creds = new CredenciaisDTO();
      creds.email = `${STORAGE_KEYS.emailUsuarioSite}`;
      creds.senha = `${STORAGE_KEYS.senhaUsuarioSite}`;

      this.storage.authenticate(creds).subscribe(response => {
        tokenAcesso = response.headers.get('Authorization');
        this.authHeader = new HttpHeaders({ 'Authorization': tokenAcesso });
      },
        error => {
          alert("Senha e/ou Login não estão corretos")
        })
    }

    // this.Products = this.http.get<Product[]>(this.apriUrl + "/loja", { 'headers': this.authHeader }).pipe(map(data => data));
    // this.Products.subscribe(data => {console.log(data)})
    // this.Products.subscribe(next => {
    //   localStorage['products'] = JSON.stringify(next);
    // });

    this.Products = this.http.get<Product[]>(this.apriUrl + "/loja", { 'headers': this.authHeader }).pipe(
      map(data => {
        // Filtrando produtos únicos com base na propriedade 'id'
        const uniqueProducts = Array.from(new Set(data.map(product => product.id))) // Supondo que 'id' seja único
          .map(id => data.find(product => product.id === id)); // Retorna os produtos filtrados

        return uniqueProducts; // Retorna apenas produtos únicos
      })
    );

    this.Products.subscribe(data => {
      console.log(data); // Exibir os produtos únicos
      localStorage.setItem('products', JSON.stringify(data)); // Armazena produtos filtrados no localStorage
    });
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.nome.replace(' ', '-') === slug;
      });
    }));
  }


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<ProductVenda[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<ProductVenda[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(productVenda): any {
    const cartItem = state.cart.find(item => item.id === productVenda.id);
    //const qty = product.quantity ? product.quantity : 1;
    const qty = 1;
    const items = cartItem ? cartItem : productVenda;
    //const items = productVenda;
    //const stock = this.calculateStockCounts(items, qty);

    //if (!stock) return false

    if (cartItem && productVenda.individual == false) {
      //    cartItem.quantity += qty    
    } else {
      state.cart.push({
        ...productVenda,
        //    quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    //const qty = product.quantity + quantity
    const qty = quantity
    const stock = product.capacidade
    if (stock < qty || stock == 0) {
      this.toastrService.error('Você não pode adicionar mais itens do que os disponíveis. Em estoque ' + stock + ' itens.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.findIndex(item => item.id === product.id);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((productVenda: ProductVenda[]) => {
      return productVenda.reduce((prev, curr: ProductVenda) => {
        let valor = curr.total;
        if (curr.discount) {
          valor = curr.valor - (curr.valor * curr.discount / 100)
        }
        return (prev + valor) * this.Currency.valor;
      }, 0);
    }));
  }


  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        } else if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.nome > b.nome) {
          return -1;
        } else if (a.nome < b.nome) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.valor < b.valor) {
          return -1;
        } else if (a.valor > b.valor) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.valor > b.valor) {
          return -1;
        } else if (a.valor < b.valor) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
