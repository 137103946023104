<ng-template class="theme-modal" #enviarEmail let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <form>
                <div class="mb-3 row">
                    <h3>Enviar Email</h3>
                </div>
                <div class="card-body">
                    <small class="text-muted pt-4 db">Nº</small>
                    <h5>{{vendaSelected.id}}</h5>
                    <small class="text-muted pt-4 db">Status</small>
                    <h5>{{vendaSelected.status}}</h5>
                    <small class="text-muted pt-4 db">Valor</small>
                    <h5>{{vendaSelected.valor | currency: 'BRL' : 'symbol'}}</h5>
                    <small class="text-muted pt-4 db">Data</small>
                    <h5>{{vendaSelected.dataVencimento | date: 'shortDate'}}</h5>
                    <small class="text-muted pt-4 db" *ngIf="vendaSelected.obs">Observações</small>
                    <h5>{{vendaSelected.obs}}</h5>
                </div>
                <div>
                    <hr>
                </div>
                <div class="text" *ngIf="idProd == null">
                    <small class="text-muted pt-4 db">Nome do Produto Vendido e Valor</small>
                    <h6 *ngFor="let prodM of vendaSelected.produtosVendidos">{{prodM.id}} - {{prodM.nome}} -
                        {{prodM.valor | currency:'BRL':'symbol'}}</h6>
                </div>
                <div class="text" *ngIf="idProd != null">
                    <small class="text-muted pt-4 db">Nome do Produto Vendido e Valor</small>
                    <div *ngFor="let prodM of vendaSelected.produtosVendidos">
                        <h6 *ngIf="prodM.id == idProd">
                            {{prodM.id}} - {{prodM.nome}} - {{prodM.valor | currency:'BRL':'symbol'}}
                        </h6>
                    </div>
                </div>
                <div>
                    <hr>
                </div>
                <div class="card-body">
                    <div class="theme-card">
                        <div class="form-group">
                            <small class="text-muted pt-4 db">Inserir email alternativo para envio</small>
                            <input type="text" class="form-control" id="emailAlternativo"
                                placeholder="Inserir Email Alternativo" name="emailAlternativo"
                                [(ngModel)]="emailAlternativo">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 row mt-3">
                    <div class="col-md-6 text-center">
                        <button class="btn btn-solid p-3" (click)="closeBtnClick()">Fechar</button>
                    </div>
                    <div class="col-md-6 text-center">
                        <button class="btn btn-solid p-3" (click)="enviarEmailArquivos(vendaSelected)"
                            *ngIf="tipo == 'comprovante'">Enviar Comprovante</button>
                        <button class="btn btn-solid p-3" (click)="enviarEmailArquivos(vendaSelected)"
                            *ngIf="tipo == 'voucher'">Enviar Voucher</button>
                    </div>
                </div>
            </form>
            <small class="text-muted pt-4 db">Será enviado um email para o usuário logado e para o email alternativo caso seja informado</small>
        </div>
    </div>
</ng-template>