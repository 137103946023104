<ng-template class="theme-modal" #cancelarVenda let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <form>
                <div class="mb-3 row">
                    <h3>Cancelar venda</h3>
                </div>
                <div class="card-body">
                    <small class="text-muted pt-4 db">Nome</small>
                    <h5>{{vendaSelected.nome}}</h5>
                    <small class="text-muted pt-4 db">Status</small>
                    <h5>{{vendaSelected.status}}</h5>
                    <small class="text-muted pt-4 db">Valor</small>
                    <h5>{{vendaSelected.valor | currency: 'BRL' : 'symbol'}}</h5>
                    <small class="text-muted pt-4 db">Data</small>
                    <h5>{{vendaSelected.dataVencimento | date: 'shortDate'}}</h5>
                    <small class="text-muted pt-4 db" *ngIf="vendaSelected.obs">Observações</small>
                    <h5>{{vendaSelected.obs}}</h5>
                </div>
                <div>
                    <hr>
                </div>
                <div class="text">
                    <small class="text-muted pt-4 db">Nome do Produto Vendido e Valor</small>
                    <h6 *ngFor="let prodM of vendaSelected.produtosVendidos">{{prodM.id}} - {{prodM.nome}} - {{prodM.valor | currency:'BRL':'symbol'}}</h6>
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-6 text-center">
                        <button class="btn btn-solid p-3" (click)="closeBtnClick()">Fechar</button>
                    </div>
                    <div class="col-md-6 text-center">
                        <button class="btn btn-danger p-3" (click)="atualizarVenda(vendaSelected, 'Cancelado')">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #esperaModal let-modal>
    <div class="modal-body">
        <form>
            <div class="mb-3 row">
                <h3 class="text-center">Os dados estão sendo gerados.</h3>
                <h4 class="text-center">Aguardo um momento por favor.</h4>
                <div [@spin]="spinState">
                    <!-- <i-feather name="clock" class="feather-sm"></i-feather> -->
                </div>
            </div>
        </form>
    </div>
</ng-template>