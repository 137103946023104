import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../../../shared/services/product.service';
import { Passageiro, ProductVenda } from 'src/app/shared/classes/productvenda';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { empty } from 'rxjs';
import { PassageiroInic } from 'src/app/shared/classes/passageiroiniciado';

@Component({
  selector: 'app-passageiro',
  templateUrl: './passageiro.component.html',
  styleUrls: ['./passageiro.component.scss'],
  styles: [
    `
			.inputpequeno {
				padding-top: 5px;
			}
		`,
  ],
})
export class PassageiroComponent implements OnInit, OnDestroy {

  @Input() productVenda: ProductVenda;
  @ViewChild("passageiro", { static: false }) Passageiro: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public inserirPassageiros: FormGroup;
  public tipo;
  public datasSelecionadas: Date = JSON.parse(localStorage.getItem('dataSelecionada'));
  public qtdPassageiros;

  public somaOpcionais;
  public opcionais;
  public adultos;
  public criancas;
  public bebes;
  public idosos;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    public productService: ProductService,
    private toastrService: ToastrService,
    private viewScroller: ViewportScroller,
  ) { }

  ngOnInit(): void {

  }

  openModal(tipo) {
    this.tipo = tipo;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.Passageiro, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Passageiro',
        backdrop: 'static'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    this.productVenda = JSON.parse(localStorage.getItem('produtoVenda'));
    console.log(this.productVenda)
    this.qtdPassageiros = this.productVenda.adultos
      + this.productVenda.criancas + this.productVenda.bebes
      + this.productVenda.idosos;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  async onSubmit() {
    this.viewScroller.scrollToPosition([0, 0]);
    let passageirosAtuais: Passageiro[] = [];
    this.productVenda.passageiros.forEach(pass => {
      if (pass?.nome) {
        passageirosAtuais.push(pass);
      }
    })

    this.productVenda.passageiros = passageirosAtuais;

    this.conferirIdadePassageiros(this.productVenda);

    let testenome = this.productVenda.passageiros.find(passag => passag.nome == '');
    let testenascimento = this.productVenda.passageiros.find(passag => passag.nascimento == null);
    if (testenome) {
      this.toastrService.error('Necessário preencher todos os campos de nome', 'Erro Nome',
        { timeOut: 7000, progressBar: true, progressAnimation: 'increasing' });
    }
    if (testenascimento) {
      this.toastrService.error('Necessário preencher todos os campos de data de nascimento', 'Erro Nascimento',
        { timeOut: 7000, progressBar: true, progressAnimation: 'increasing' });
    }

    if (testenome || testenascimento) {
      this.toastrService.error('Não foi possível gravar os dados', 'Erro',
        { timeOut: 7000, progressBar: true, progressAnimation: 'increasing' });
    } else {
      // this.productVenda.tarifas = this.productVenda.tarifas.filter(tarifa => tarifa.status == 'Ativo'
      //   && new Date(tarifa.data.data).getFullYear() == new Date(this.datasSelecionadas).getFullYear()
      //   && new Date(tarifa.data.data).getMonth() == new Date(this.datasSelecionadas).getMonth()
      //   && new Date(tarifa.data.data).getDate() == new Date(this.datasSelecionadas).getDate()
      // )]
      this.productVenda.opcionais = this.productVenda.opcionais.filter(op => op.checked == true)
      var somaOpcionais = 0;
      var opcionais = this.productVenda.opcionais.filter(op => op.checked == true);
      opcionais.forEach(op => somaOpcionais += (op.valor * op.quantidade));
      if (this.productVenda.trechoProduto != null && this.productVenda.produtoAlterarValor == false) {
        this.productVenda.valor = this.productVenda.trechoProduto.valor;
        this.productVenda.total = this.productVenda.trechoProduto.valor + somaOpcionais;
        var status = this.productService.addToCart(this.productVenda);
        if (this.productVenda.idaEVolta) {
          this.idaEVolta();
        }

      } else if (this.productVenda.produtoAlterarValor == true) {
        if (this.productVenda.idaEVolta) {
          this.productVenda.total = this.productVenda.valor / 2;
          var status = this.productService.addToCart(this.productVenda);
          this.idaEVolta();
        } else {
          this.productVenda.total = this.productVenda.valor;
          var status = this.productService.addToCart(this.productVenda);
        }
      }

      else {
        var status = this.productService.addToCart(this.productVenda);
      }

      if (status) {
        if (this.tipo == 'comprar') {
          this.router.navigate(['/shop/checkout']);
        } else {
          this.router.navigate(['/shop/cart']);
        }
      }
      this.productService.cartItems.subscribe(response => {
      });
      this.modalService.dismissAll();
    }
  }

  inserirDadosPassageiro(idPassageiro: any, event: any, variavel: string) {
    let val = event.target.value;

    if (variavel == "nome") this.productVenda.passageiros[idPassageiro].nome = val;
    if (variavel == "nascimento") this.productVenda.passageiros[idPassageiro].nascimento = val;
    if (variavel == "cpf") this.productVenda.passageiros[idPassageiro].cpf = val;
    if (variavel == "telefone") this.productVenda.passageiros[idPassageiro].telefone = val;
    if (variavel == "celular") this.productVenda.passageiros[idPassageiro].celular = val;
    if (variavel == "email") this.productVenda.passageiros[idPassageiro].email = val;
    if (variavel == "obs") this.productVenda.passageiros[idPassageiro].obs = val;
  }

  public removeItem(idPassageiro: any) {
    this.productVenda.passageiros.splice(idPassageiro, 1);
    console.log(this.productVenda)
    //delete this.productVenda.passageiros[idPassageiro];
  }

  adicionarPassageiro() {
    if (this.productVenda.passageiros.length >= this.productVenda.tarifa.capacidade) {
      alert("Não há mais vagas")
    } else {
      this.productVenda.passageiros.push(new PassageiroInic);
    }
  }

  idaEVolta() {
    var origem = this.productVenda.trechoProduto.destino;
    var destino = this.productVenda.trechoProduto.origem;
    this.productVenda.trechoProduto.origem = origem;
    this.productVenda.trechoProduto.destino = destino;
    this.productVenda.dataIda = this.productVenda.dataVolta;
    this.productVenda.dataVolta = null;
    this.productService.addToCart(this.productVenda);
  }

  async conferirIdadePassageiros(prodV) {


    var hoje = new Date();
    var hoje1 = new Date();
    var hoje2 = new Date();
    var dataAdulto = new Date(hoje.setFullYear(hoje.getFullYear() - 11));
    var dataCrianca = new Date(hoje1.setFullYear(hoje1.getFullYear() - 3));
    var dataIdoso = new Date(hoje2.setFullYear(hoje2.getFullYear() - 60));

    this.somaOpcionais = 0;

    this.opcionais = prodV.opcionais.filter(op => op.checked == true);
    this.opcionais.forEach(op => this.somaOpcionais += (op.valor * op.quantidade));

    this.adultos = 0;
    this.criancas = 0;
    this.bebes = 0;
    this.idosos = 0;

    this.adultos = prodV.passageiros.filter(passageiros =>
      new Date(passageiros.nascimento) < dataAdulto && new Date(passageiros.nascimento) > dataIdoso).length;
    this.criancas = prodV.passageiros.filter(passageiros =>
      new Date(passageiros.nascimento) < dataCrianca && new Date(passageiros.nascimento) > dataAdulto).length;
    this.bebes = prodV.passageiros.filter(passageiros =>
      new Date(passageiros.nascimento) > dataCrianca).length;
    this.idosos = prodV.passageiros.filter(passageiros =>
      new Date(passageiros.nascimento) < dataIdoso).length;

    this.productVenda.adultos = this.adultos;
    this.productVenda.criancas = this.criancas;
    this.productVenda.bebes = this.bebes;
    this.productVenda.idosos = this.idosos;

    if (prodV.individual == false && prodV.produtoAlterarValor == false) {
      this.productVenda.total = (prodV.valor * this.adultos) + (prodV.valorCrianca * this.criancas)
        + (prodV.valorBebe * this.bebes) + (prodV.valorIdoso * this.idosos) + this.somaOpcionais;
    } else {
      this.productVenda.total = prodV.valor + this.somaOpcionais;
    }
  }
}
