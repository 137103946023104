import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_CONFIG } from "src/config/api.config";
import { ProductVenda } from "../classes/productvenda";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})

export class ProdutoVendaService {
    apriUrl = `${API_CONFIG.baseUrl}/produtovendido`;
    postId;
    putId;

    token = this.storage.getLocalUser() ? this.storage.getLocalUser().token : null;
    authHeader = this.token ? new HttpHeaders({ 'Authorization': 'Bearer ' + this.token }) : new HttpHeaders();

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private httpClient: HttpClient,
        private storage: AuthService
    ) { }

    public getProdutosVendidos(): Observable<ProductVenda[]> {
        return this.httpClient.get<ProductVenda[]>(this.apriUrl, { 'headers': this.authHeader });
    }

    public getProdutoVendidoById(ident: any): Observable<any[]> {
        return this.httpClient.get<any[]>(this.apriUrl + '/' + ident, { 'headers': this.authHeader });
    }

    public getCapacidade(ident: any, identProdV: any): Observable<ProductVenda[]> {
        return this.httpClient.get<ProductVenda[]>(this.apriUrl + '/capacidade/' + ident + "?idProdV=" + identProdV, { 'headers': this.authHeader });
    }

    public postProdutoVendido(produto: any) {
        return this.httpClient.post<any>(this.apriUrl, produto, { 'headers': this.authHeader });
    }

    public putProdutoVendido(produto: any, ident: any): Observable<any[]> {
        return this.httpClient.put<any>(this.apriUrl + '/' + ident, produto, { 'headers': this.authHeader });
    }

    public async deleteProdutoVendido(ident: any) {
        return this.httpClient.delete<any>(this.apriUrl + '/' + ident, { 'headers': this.authHeader }).subscribe(
            data => { this.putId = data.id; }
        );
    }

    public getVoucher(idProdutoVendido: any): Observable<Blob> {
        return this.httpClient.get(this.apriUrl + '/voucher/' + idProdutoVendido, {
            responseType: 'blob',
            'headers': this.authHeader
        });
    }

    public getEmailVoucher(idVenda: any, email: any): Observable<any> {
        return this.httpClient.get<any>(this.apriUrl + '/voucherenviar/' + idVenda + '?email=' + email, {
            'headers': this.authHeader
        });
    }
}