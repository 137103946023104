import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_CONFIG } from "src/config/api.config";
import { User } from "../dto/user";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})

export class UsuarioService {
    apriUrl = `${API_CONFIG.baseUrl}/usuario`;
    postId;
    putId;

    token = this.storage?.getLocalUser()?.token;
    authHeader = new HttpHeaders({'Authorization':'Bearer ' + this.storage?.getLocalUser()?.token});

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private httpClient: HttpClient,
        private storage: AuthService
    ) { }

    public getUsuario(): Observable<User[]> {
        return this.httpClient.get<User[]>(this.apriUrl, {'headers': this.authHeader});
    }

    public getUsuarioById(ident: any): Observable<User[]> {
        return this.httpClient.get<User[]>(this.apriUrl + '/' + ident, {'headers': this.authHeader});
    }

    public getUsuarioByIdS(ident: any): Observable<User> {
        return this.httpClient.get<User>(this.apriUrl + '/' + ident, {'headers': this.authHeader});
    }

    public getUsuarioByIdSite(ident: any, token: any): Observable<User> {
        this.authHeader = new HttpHeaders({'Authorization': token});
        return this.httpClient.get<User>(this.apriUrl + '/' + ident, {'headers': this.authHeader});
    }

    public getUsuarioByIdV(ident: any, token: any): Observable<User> {
        this.authHeader = new HttpHeaders({'Authorization':'Bearer ' + token});
        return this.httpClient.get<User>(this.apriUrl + '/' + ident, {'headers': this.authHeader});
    }

    public getUsuarioByEmail(email: string): Observable<User[]> {
        return this.httpClient.get<User[]>(this.apriUrl + '/email/' + email, {'headers': this.authHeader});
    }

    public postUsuario(usuario: any, idPerfis: Number[]): Observable<any[]>{
        return this.httpClient.post<any>(this.apriUrl + '?idPerfis=' + idPerfis, usuario, {'headers': this.authHeader});
    }

    public async putUsuario(usuario: any, ident: any, idPerfis: Number[]) {
        return this.httpClient.put<any>(this.apriUrl + '/' + ident + '?idPerfis=' + idPerfis, usuario, {'headers': this.authHeader}).subscribe(
            data => { this.putId = data.id; }
        );
    }

    public async deleteUsuario(ident: any) {
        return this.httpClient.delete<any>(this.apriUrl + '/' + ident, {'headers': this.authHeader}).subscribe(
            data => { this.putId = data.id; }
        );
    }

    public async putSenhaT(senha: any, ident: any) {
        console.log("Imprimindo informarcoes de senha", senha, ident)
        return this.httpClient.put<any>(this.apriUrl + '/senhaT/' + ident, senha, {'headers': this.authHeader}).subscribe();
    } 
}